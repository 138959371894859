html{
  scroll-behavior: smooth;
}

body {
  height: 100vh;
}

a.chakra-link:focus{
  box-shadow: none;
}

article p {
  margin-top: 1em;
}

article section {
  margin-top: 2.5em;
}

article ul {
  margin-top: 1em;
  margin-left: 2em;
}

article a,
a.link {
  color: var(--chakra-colors-brand-500);
}